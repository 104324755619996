.dropzone {
  border: 2px dashed #999;
  border-radius: 3px;
  background-color: #f8f8ff;
  text-align: center;
  height: 10rem;
}
.center2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.upload-icon {
  margin-top: 3rem;
  font-size: 40px;
}

.upload-input {
  position: relative;
  top: -110px;
  left: 0;
  width: 100%;
  height: 10rem;
  opacity: 0;
}
