/* Everywhere */
* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
  background-color: #f8f8ff;
  font-display: swap;
  font-family: "Open Sans", Impact, Charcoal, sans-serif;
}

.hide {
  display: none;
}
.menu {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.menu2 {
  text-align: right;
}
.wide {
  animation: stretch-in 0.8s ease-out 0s alternate both running;
}
.wide2 {
  animation: stretch-out 0.8s ease-out 0s alternate both running;
}
@keyframes stretch-in {
  0% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes stretch-out {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.menuButton {
  background-color: #f5f5f5 !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.menuButton2 {
  background-color: #f5f5f5 !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.card2 {
  background-color: #e5e4e2;
}
.card3 {
  background-color: #f5f5f5;
}
.active {
  padding-bottom: 2px;
  border-bottom: 2px solid #d9230f;
}
.navbar-nav > li > a {
  padding-top: 8px !important;
}

.navbar-brand {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.btn-outline-info {
  color: #087e8b;
  border-color: #087e8b;
}
.btn-outline-info:hover {
  background-color: #087e8b;
  border-color: #087e8b;
  color: #ffffff;
}

.ql-container {
  min-height: 180px;
  max-height: 350px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.app {
  min-height: 100vh;
}
@media screen and (max-width: 600px) {
  .navbar-nav {
    flex-direction: row;
  }
  .navbar-nav > li {
    padding-right: 0.65rem;
  }
  .fit {
    margin-left: 15vw !important;
  }
  .hideContact {
    display: none;
  }
  .app {
    min-height: auto;
  }
}

@media screen and (max-width: 754px) and (min-width: 601px) {
  .navbar-nav {
    flex-direction: row;
  }
  .navbar-nav > li {
    padding-right: 3rem;
  }
  .hideContact {
    display: none;
  }
  .app {
    min-height: auto;
  }
}
@media screen and (max-width: 992px) and (min-width: 755px) {
  .navbar-nav {
    flex-direction: row;
  }
  .navbar-nav > li {
    padding-right: 4rem;
  }
  .app {
    min-height: 90vh;
  }
}
@media screen and (max-width: 1024px) and (min-width: 993px) {
  .app {
    min-height: 90vh;
  }
}
input {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.btn:focus,
.btn:active {
  outline: 0 !important;
  box-shadow: none !important;
}
/*Create ripple effect for button*/

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.6s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}
.enrolledButton {
  padding: 0.31rem 0.75rem !important;
}
.buttonFix {
  padding: 0.2rem 0.2rem !important;
}

.contain .price {
  position: absolute;
  top: 0;
  font-size: 12px;
  font-weight: bold;
  color: #2c3e50;
  padding-top: 7px;
  padding-left: 5px;
  height: 2.2rem;
  width: 2.9rem;
  background-clip: padding-box;
  border-top-left-radius: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 0px;
}

.gmd-1 {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.gmd-1:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.react-multiple-carousel__arrow {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.react-multiple-carousel__arrow:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.react-multiple-carousel__arrow::before {
  color: #2c3e50 !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.MuiCardContent-root {
  padding: 8px !important;
}
.MuiTabScrollButton-root {
  color: #087e8b !important;
}

div {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.form-control {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navbar {
  padding: 0;
}
.searchButton {
  padding: 5px !important;
}

.MuiIconButton-root {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 1.25rem !important;
}

.MuiIconButton-root:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.MuiButtonBase-root:focus {
  outline: none !important;
}

.MuiAvatar-root:focus {
  outline: none !important;
}

.MuiFab-root:hover {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
  /* border-radius: 50%;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-width: 0.1rem; */
}

/* SignUp, SignIn, EditUser, ForgotPassword */
.formSignup {
  width: 100%;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 1rem;
}

.formEdit {
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 2rem;
  margin-top: 3rem;
}

.formEdit img {
  margin-bottom: 1.5rem !important;
  vertical-align: middle;
  border-style: none;
}

.cardShadow {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.cardShadow:hover {
  box-shadow: 0 4px 5px rgba(44, 62, 80, 0.3);
}

.formSignup input,
.formEdit input {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.brand {
  margin: -8px -8px -8px -8px;
  padding: -8px -8px -8px -8px;
  height: 70px;
}

.brandColor1 {
  color: "#f5c63f";
}

.brandColor2 {
  color: "#f57e21";
}

.formSignup button,
.formEdit button {
  margin-top: 2rem;
}

.editImage {
  height: 15rem !important;
  width: 20rem !important;
}

/* Profile */
.profilePic {
  width: 15rem;
  height: 15rem;
}

.profile_bio {
  max-height: 10rem;
}

.MuiIconButton-colorPrimary:hover {
  color: #0056b3 !important;
}

.card-img-top {
  object-fit: cover;
  height: 160px;
}

.deleteModal {
  width: 15rem !important;
}

.MuiTabs-root {
  overflow: visible !important;
}

.textarea {
  resize: both;
}

.makeStyles-root-73 {
  background-color: #f8f8ff !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23eeeeee' fill-opacity='0.36' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.courseFont {
  font-family: "Open Sans", sans-serif !important;
}
.shortDesc {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300 !important;
}
.MuiTypography-body1 {
  font-family: "Open Sans", sans-serif !important;
}
.MuiTypography-body2 {
  font-family: "Open Sans", sans-serif !important;
}
.MuiTypography-subtitle1 {
  font-family: "Open Sans", sans-serif !important;
}
.MuiTypography-subtitle2 {
  font-family: "Open Sans", sans-serif !important;
}
.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
  font-family: "Open Sans", sans-serif !important;
}

/*
Extra small devices (portrait phones, less than 544px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/
.text_responsive {
  font-size: 2.5rem;
} /*1rem = 16px*/

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
  .text_responsive {
    font-size: 1.5rem;
  } /*1rem = 16px*/
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .text_responsive {
    font-size: 2rem;
  } /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .text_responsive {
    font-size: 2.5rem;
  } /*1rem = 16px*/
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .text_responsive {
    font-size: 3rem;
  } /*1rem = 16px*/
}

/* Set width to make card deck cards 100% width */
@media (min-width: 950px) and (max-width: 1100px) {
  .text_responsive {
    font-size: 2.75rem;
  }
}

.dropIn {
  max-width: 65vw;
}

/* Footer */
/*
Extra small devices (portrait phones, less than 544px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/
footer {
  width: 100%;
} /*1rem = 16px*/

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
  footer {
    top: auto;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  footer {
    bottom: 0;
  }
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #d9230f;
}

.glowing-border {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px !important;
}

.glowing-border2 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 20px !important;
}

.buttonFix {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

@media (max-width: 600px) {
  .mobile-hide {
    display: none;
    visibility: hidden;
  }

  .titleAlign2 {
    text-align: center;
  }
}

.thumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.overlay {
  position: relative;
}

.overlay .thumbnail2 {
  display: block;
  width: 350px;
  object-fit: cover;
}

.overlay .time {
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: 3px;
  padding: 2px 5px;
  background-color: #d9230f;
  color: white;
}

.overlay .playWrapper {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6) url("./images/play.png") no-repeat scroll
    center center / 50px 50px;
  transition: transform 0.2s, opacity 0.6s;
}

.playWrapper .playBtn {
  position: absolute;
  z-index: 2;
  width: 50px;
  height: 50px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; /* center */
}

.thumb .overlay:hover .playWrapper {
  opacity: 1;
}

.newCard {
  border: 2px solid #d9230f;
  box-shadow: 5px 6px 0px #d9230f;
  transition: 0.3s all;
  border-radius: 3px;
}

.product-img {
  transition: all 0.5s linear;
  &:hover {
    transform: scale(1.03);
  }
}
