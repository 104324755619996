.footer {
  background: #d9230f;
  color: #f8f8ff;
  //position: relative;
  font-size: 15px;

  .footer-top {
    padding: 30px 0 20px 0;
    .footer-des {
      margin-top: -45px;
      margin-bottom: 15px;
      background: #a51505;
      color: #fff;
      border-top: 4px solid #fff;
      border-radius: 77% 23% 78% 22% / 16% 84% 16% 84%;
      text-align: center;
      padding: 30px 20px;
    }
  }

  .footer-credits {
    font-size: 12px;
  }
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}
