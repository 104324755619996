.icons {
  color: #087e8b;
  font-size: 25px;
  -webkit-text-stroke: 0px #087e8b;
}
.icons2 {
  color: #d9230f;
  font-size: 25px;
  -webkit-text-stroke: 0px #d9230f;
}
