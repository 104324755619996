.live {
  color: #d9230f;
}
.sidenav span {
  font-size: 0.9rem !important;
}
.side-nav-item {
  cursor: pointer;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
