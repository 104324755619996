.submit {
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedContent {
  max-height: 40rem;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.selectedContent::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

.MuiBox-root {
  padding: 0px !important;
}

.list-item {
  cursor: move;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.list-item:hover {
  background: #f5f5f8f8;
  -webkit-box-shadow: 0 4px 6px rgba(137, 137, 137, 0.19),
    0 2px 2px rgba(135, 135, 135, 0.23);
  -moz-box-shadow: 0 4px 6px rgba(137, 137, 137, 0.19),
    0 2px 2px rgba(135, 135, 135, 0.23);
  -ms-box-shadow: 0 4px 6px rgba(137, 137, 137, 0.19),
    0 2px 2px rgba(135, 135, 135, 0.23);
  -o-box-shadow: 0 4px 6px rgba(137, 137, 137, 0.19),
    0 2px 2px rgba(135, 135, 135, 0.23);
  box-shadow: 0 4px 6px rgba(137, 137, 137, 0.19),
    0 2px 2px rgba(135, 135, 135, 0.23);
}
